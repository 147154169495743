import './sentrySetup';
import { environment } from '@core/environment';
import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-license';
import { App } from './App';
import './i18n.config';
import './index.css';

declare module 'valtio' {
  // prevents annoying "readonly" cannot be assigned to mutable property error
  // ATTENTION: breaks typescript when in static.d.ts https://github.com/pmndrs/valtio/issues/327#issuecomment-1142536109
  export function useSnapshot<T extends object>(p: T): T;
}

if (environment.useMockApi) {
  // if npm:with-mock-api script exits with error on start, try setting your VITE_ENABLE_PRODUCTION_PROFILING=false in your .env.development
  import('./core/api/__mocks__/browser');
}

LicenseInfo.setLicenseKey(environment.muiProLicenceKey);

const debug = false;

ReactDOM.render(
  debug ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
  document.getElementById('root'),
);
