import { Scan } from '@scans/types';
import { SelectionArea } from '@viewer3D/components/SelectionArea';
import { Object3dNames } from '@viewer3D/types';
import { useTheme } from '@mui/material';
import { checkAllScans } from './checkboxSelectionState';

export const ScanSelectionArea = ({ scans }: { scans: Scan[] }) => {
  const theme = useTheme();
  return (
    <SelectionArea
      style={{
        backgroundColor: `${theme.palette.primary.main}`,
        opacity: 0.3,
        position: 'fixed',
      }}
      onSelectionChanged={(objs) => {
        if (objs.length === 0) return;
        const scanIds = objs
          .filter((obj) => obj.name.includes(Object3dNames.Scan))
          .map((obj) => obj.userData?.scanId);

        const scansToCheck = scans.filter((scan) => scanIds.includes(scan.id));

        checkAllScans(scansToCheck);
      }}
    />
  );
};
