// taken and modified from https://gist.github.com/NickAkhmetov/816c345fc9eaebac1e5dc7d076fda56b
import { useKey } from '@core/logic';
import { useThree } from '@react-three/fiber';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BufferGeometry, Material, Mesh, Object3D, Vector2 } from 'three';
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox';

/**
 * Converts client coordinates to normalized device coordinates (-1 to +1)
 */
const getCoords = (clientX: number, clientY: number): [number, number] => [
  (clientX / window.innerWidth) * 2 - 1,
  -(clientY / window.innerHeight) * 2 + 1,
];

interface SelectableObject extends Mesh<BufferGeometry, Material | Material[]> {}

interface SelectionProps {
  onSelectionChanged?(objects: Object3D[]): void;
  style?: CSSProperties;
}

export const SelectionArea: FC<SelectionProps> = ({ style, onSelectionChanged }) => {
  const { camera, scene, gl } = useThree();
  const [start, setStart] = useState<Vector2>();
  const [mouse, setMouse] = useState<[number, number]>();
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const canSelect = useKey('Shift');
  const [selection, setSelection] = useState<Object3D[]>([]);
  const selectRectangle = useRef(document.createElement('div'));
  const selectionBox = useMemo(() => new SelectionBox(camera, scene), [scene, camera]);

  /**
   * Applies styles to the selection rectangle element
   */
  const applySelectRectangleStyles = useCallback(
    (element: HTMLDivElement, styles?: CSSProperties) => {
      element.classList.add('selectBox');
      element.style.pointerEvents = 'none';

      if (styles) {
        for (const [key, value] of Object.entries(styles)) {
          element.style.setProperty(
            key
              .replace(/([a-z])([A-Z])/g, '$1-$2')
              .replace(/[\s_]+/g, '-')
              .toLowerCase(),
            String(value),
          );
        }
      }
    },
    [],
  );

  /**
   * Updates the selection rectangle position and dimensions
   */
  const updateSelectionRectangle = useCallback(
    (
      element: HTMLDivElement,
      startPoint: Vector2,
      currentMouse: [number, number],
      container: HTMLElement,
    ) => {
      container.append(element);

      const topLeft = {
        x: Math.min(startPoint.x, currentMouse[0]),
        y: Math.min(startPoint.y, currentMouse[1]),
      };
      const bottomRight = {
        x: Math.max(startPoint.x, currentMouse[0]),
        y: Math.max(startPoint.y, currentMouse[1]),
      };

      element.style.left = `${topLeft.x}px`;
      element.style.top = `${topLeft.y}px`;
      element.style.width = `${bottomRight.x - topLeft.x}px`;
      element.style.height = `${bottomRight.y - topLeft.y}px`;
    },
    [],
  );

  /**
   * Adds new objects to the selection if they're not already selected
   */
  const appendSelection = useCallback(
    (toAppend: SelectableObject[]) => {
      const uniqueObjects = toAppend.filter(
        (obj) => !selection.some((existing) => existing.uuid === obj.uuid),
      );
      setSelection([...selection, ...uniqueObjects]);
    },
    [selection],
  );

  const onPointerDown = useCallback(
    (e: PointerEvent) => {
      const { clientX, clientY, altKey, button } = e;
      if (!altKey && !isSelecting && !button) {
        const [startX, startY] = getCoords(clientX, clientY);
        setStart(new Vector2(clientX, clientY));
        setIsSelecting(true);
        selectionBox.startPoint.set(startX, startY, 0.5);
        selectionBox.endPoint.set(startX, startY, 0.5);
      }
    },
    [isSelecting, selectionBox],
  );

  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      if (!isSelecting) return;
      const { clientX, clientY } = e;
      const [endX, endY] = getCoords(clientX, clientY);
      setMouse([clientX, clientY]);
      selectionBox.endPoint.set(endX, endY, 0.5);
      selectionBox.select();
    },
    [isSelecting, selectionBox],
  );

  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      const { clientX, clientY, button } = e;

      if (isSelecting || !button) {
        setIsSelecting(false);

        const [endX, endY] = getCoords(clientX, clientY);
        selectionBox.endPoint.set(endX, endY, 0.5);

        const diff = selectionBox.endPoint.clone().sub(selectionBox.startPoint);
        // if the difference is less than 0.0001, then we are not selecting anything
        if (diff.length() < 0.0001) {
          setIsSelecting(false);
          return;
        }

        const curSelected = selectionBox.select();

        setMouse(undefined);
        setStart(undefined);

        appendSelection(curSelected);
      }
    },
    [isSelecting, selectionBox, appendSelection],
  );

  // Apply initial styles to selection rectangle
  useEffect(() => {
    applySelectRectangleStyles(selectRectangle.current, style);
  }, [style, applySelectRectangleStyles]);

  // Update selection rectangle visibility and dimensions
  useEffect(() => {
    if (isSelecting && start && mouse && gl.domElement.parentElement) {
      updateSelectionRectangle(selectRectangle.current, start, mouse, gl.domElement.parentElement);
    } else {
      selectRectangle.current.parentElement?.removeChild(selectRectangle.current);
    }
  }, [isSelecting, gl, start, mouse, updateSelectionRectangle]);

  // Notify parent component of selection changes
  useEffect(() => {
    onSelectionChanged?.call(null, selection);
  }, [selection, onSelectionChanged]);

  // Handle pointer events and selection state
  useEffect(() => {
    if (!canSelect && selection.length > 0) {
      setIsSelecting(false);
      setSelection([]);
      return;
    }
    if (!canSelect) {
      setIsSelecting(false);
      return;
    }

    const element = gl.domElement;
    element.addEventListener('pointerdown', onPointerDown);
    element.addEventListener('pointermove', onPointerMove);
    element.addEventListener('pointerup', onPointerUp);

    return () => {
      element.removeEventListener('pointerdown', onPointerDown);
      element.removeEventListener('pointermove', onPointerMove);
      element.removeEventListener('pointerup', onPointerUp);
    };
  }, [gl.domElement, onPointerDown, onPointerMove, onPointerUp, canSelect, selection.length]);

  return <></>;
};
