import { useEffectOnce, useFocusEntity } from '@core/logic';
import { PhotoIcons } from '@photos/components';
import { useThree } from '@react-three/fiber';
import { Scans } from '@scans/components';
import type { Scan } from '@scans/types';
import { Trenches } from '@trenches/components';
import { PrelabeledTrenches } from '@trenches/components/Prelabeled';
import type { MouseSphereMesh } from '@viewer3D/components';
import { Controls, FilterViewport, MouseSphere } from '@viewer3D/components';
import { Map } from '@viewer3D/components/Map';
import { useRef } from 'react';
import { Object3D, Vector3 } from 'three';
import { ExternalData } from './ExternalData';
import type { ScanFilters } from './hooks';
import { ScanSelectionArea } from './menu/ScanOrganization/ScansInScopeList/ScanSelectionArea';

/**
 * CAUTION: CHANGE AXIS CONVENTION (Z IS UP)
 */
Object3D.DefaultUp = new Vector3(0, 0, 1);

const useRaycastPerformance = () => {
  const raycaster = useThree(({ raycaster: r }) => r);
  // performance optimization to only intersect 3D objects on a single layer https://threejs.org/docs/#api/en/core/Raycaster.layers
  useEffectOnce(() => raycaster.layers.set(0));
};

export const MapLayers = ({
  scans,
  viewport,
  dateRange,
}: {
  scans: Scan[];
} & Pick<ScanFilters, 'viewport' | 'dateRange'>) => {
  const mouseSphereRef = useRef<MouseSphereMesh>(null);
  useRaycastPerformance();
  useFocusEntity();

  return (
    <>
      <Controls />
      <MouseSphere ref={mouseSphereRef} />
      <ScanSelectionArea scans={scans} />
      <FilterViewport viewport={viewport} />
      <Trenches viewport={viewport} />
      <PrelabeledTrenches />
      <ExternalData />
      <Scans mouseSphereRef={mouseSphereRef} scans={scans} />
      <Map />
      <PhotoIcons viewport={viewport} dateRange={dateRange} />
    </>
  );
};
