import { useKey } from '@core/logic';
import { MapControls } from '@react-three/drei';
import { extend, useThree } from '@react-three/fiber';
import { jsonToVector } from '@viewer3D/helper';
import { FC, useCallback, useEffect, useRef } from 'react';
import type { Event, OrthographicCamera } from 'three';
import type { MapControls as MapControlsType } from 'three-stdlib/controls/OrbitControls';
import { connectComponent } from './connect';
import { updateControlsCameraPosition } from './helper';
import type { MergedProps } from './types';

extend({ MapControls });

const CameraControlsBase: FC<MergedProps> = ({ goTo, setGoto, setMapControls, updateViewport }) => {
  const camera = useThree((state) => state.camera);
  const mapRef = useRef<MapControlsType>(null);
  const addPressed = useKey('a');
  const selectionAreaPressed = useKey('Shift');

  const updateCameraBounds = useCallback(
    (event?: Event) => {
      if (!event) return;
      const { target, object } = event.target as MapControlsType;
      const { zoom } = object as OrthographicCamera;
      const { x, y, z } = target;
      updateViewport(zoom, x, y, z);
    },
    [updateViewport],
  );

  useEffect(() => {
    if (!mapRef.current || !setMapControls) return;
    setMapControls(mapRef.current);
    camera.userData.mapControl = mapRef.current;
  }, [camera.userData, setMapControls]);

  useEffect(() => {
    const { current: map } = mapRef;
    if (!map || !goTo) return;
    updateControlsCameraPosition(map, jsonToVector(goTo));
    updateCameraBounds({ type: 'end', target: map });
    setGoto();
  }, [setGoto, goTo, updateCameraBounds]);

  /**
   * Unfortunately we need to add/remove the listener manually because component has a bug
   * <MapControls onEnd={**THIS IS LOOSING CONTEXT & NOT WORKING CORRECTLY**}/>
   */
  useEffect(() => {
    const { current: map } = mapRef;
    if (!map) return;
    map.addEventListener('end', updateCameraBounds);
    return () => {
      map.removeEventListener('end', updateCameraBounds);
    };
  }, [updateCameraBounds]);

  return (
    <MapControls
      ref={mapRef}
      enableDamping={false}
      enablePan={!addPressed && !selectionAreaPressed}
      enableRotate={!addPressed && !selectionAreaPressed}
    />
  );
};

export const CameraControls = connectComponent(CameraControlsBase);
